import React from 'react'
import { Link, Navigate, Outlet } from 'react-router-dom'
import authImage from '../../assets/images/auth-img2.webp'
import Logo from '../../assets/images/Logo-lg.svg'
import useStore from '../../contexts/AuthProvider';

const AuthMaster = ({ children }) => {

  const state = useStore();
  const isAuthenticated = state.isAuthenticated;

  if (isAuthenticated && state.set2FA !== true) {
    return <Navigate to={'/dashboard'} />;
  }

  return (
    <main className='auth-main h-screen w-full overflow-y-auto theme-scrollbar'>
      <div className="auth_main-wrapper h-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0">
          <div className={`auth-image-part hidden lg:block bg-cover bg-top `} style={{backgroundImage : `url(${authImage})`}}>
            {/* <img src={authImage} alt='' className='object-top' /> */}
          </div>
          <div className="auth-form-part overflow-y-auto theme-scrollbar h-screen max-h-full">
            <div className="bg-dark-950 p-3 lg:py-5 lg:px-10">
              <div className="auth-form-content">
                <Link to="/" className="logo-part block cursor-pointer mb-3 lg:mb-7">
                  <img src={Logo} alt="" className='mx-auto' width={"220.8px"} height={"60px"} />
                </Link>
                <div className="auth-pages-form max-w-[550px] mx-auto">
                  <Outlet />
                  {/* {children} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AuthMaster