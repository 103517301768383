import React, { useEffect, useState } from "react";
import { CMS_API_URL } from "../api/Path";
import Http from "../api/Http";
import { withTranslation } from "react-i18next";
import DataLoader from "../components/common/DataLoader";
import { Link } from "react-router-dom";
import logo from "../assets/images/Logo-lg.svg";
const PrivacyPolicy = ({ t }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  async function getData() {
    const res = await Http.get(CMS_API_URL.getPrivacyPolicy);
    setData(res?.data);
    setLoading(false);
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <DataLoader isLoading={loading} loaderClass="border-white" />
      <div className="pb-12">
        <div className="logo-part flex items-center gap-6 bg-dark-800 mb-6">
          <div className="container">
            <div className="py-4">
              <Link to={"/"}>
                <img src={logo} alt="" className="h-12 w-36" />
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <h4 className="text-50 text-white relative font-1000 mb-6 text-center">
            {t("privacyPolicy")}
          </h4>
          <div
            dangerouslySetInnerHTML={{ __html: data }}
            className="terms-content text-white"
          ></div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(PrivacyPolicy);
